import React from "react"
import { graphql } from "gatsby"
import { DiscussionEmbed } from "disqus-react";
import styles from './blog-post.module.css';

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Tag from "../components/Tag"
import Metadata from "../components/Metadata"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const image = siteUrl + this.props.data.markdownRemark.frontmatter.image.publicURL

    const disqusShortname = "pr0gramista";
    const disqusConfig = {
      url: 'https://pr0gramista.pl/post' + this.props.pageContext.slug,
      identifier: 'https://pr0gramista.pl/post' + this.props.pageContext.slug,
      title: post.frontmatter.title,
    };

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={image}
        />
        <article className={styles.article}>
          <h1>{post.frontmatter.title}</h1>
          <Metadata publishedDate={post.frontmatter.date} timeToRead={post.timeToRead} />
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div>
            {post.frontmatter.tags.map(tag => <Tag key={tag} tag={tag} />)}
          </div>
        </article>
        <Bio />
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        image {
          publicURL
        }
      }
    }
  }
`
